.user-profile-screen {
  .profile-container {
    padding: 20px;

    .profile-information-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .full {
        justify-content: center;
      }

      .profile-information-description {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span:first-child {
          font-size: 16px;
        }
        span:last-child {
          font-size: 9px;
        }
      }

      .profile-code-info-container {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 85%;

        button {
          width: 150px;
          margin-top: 5px;
        }
      }

      .profile-change-password-btn {
        // margin-top: 10px;
        // font-size: 13px;
        // line-height: 13px;
        // // height: 3.5em;
      }
    }
  }
}
