.empty-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #adadad;
    min-height: 250px;
    
    &.magin-gap {
        margin: 15px;
    }
    
    .ant-empty-image {
        margin-bottom: 20px;
    }
    
    ion-icon {
        font-size: 110px;
        color: #e6e6e6;
    }
    
    .ant-empty-description {
        margin-bottom: 10px;
    }
}