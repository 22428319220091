.loader-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    .loader-title {
        margin-top: 20px;
    }
    
    .loader-description {

    }
}