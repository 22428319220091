.an-slider {
    border: 1px solid #ddd;
    border-radius: 2em;
    height: 50px;
    
    .an-slider-value-label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 12px;
        right: 10px;
        font-size: 13px;
    }
    
    .an-slider-control-container {
        position: relative;
        padding: 8px 40px;
        
        .ant-typography {
            position: absolute;
            top: 14px;
            width: 25px;
            height: 19px;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.25);
            text-align: center;
            font-weight: 500;
        }
        
        .ant-typography:first-child {
            left: 10px;
        }
        
        .ant-typography:last-child {
            right: 10px;
        }
    }
}