.an-select {
    .ant-tag {
        flex: 1 1 50%;
        display: flex;
        margin: 0;
        position: relative;
        margin-bottom: 8px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .ant-tag-close-icon {
        position: absolute;
        right: 15px;
        top: 11px;
    }
}