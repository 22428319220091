.page-header-container {
    padding: 12px 15px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed #f0f0f0;
    
    &.beta-scaffolding {
        padding: 15px 0px;
        margin: 0px 15px;
    }

    .page-header-title {
        margin: 0;
        line-height: 25px;
        font-size: 15px;
        font-family: "Poppins";
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .page-header-subtitle {
        margin: 0px;
        font-size: 14px;
        color: #c2c0c0;
    }
}