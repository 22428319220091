html {
    background-color: #f9f9f9;   
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Poppins', cursive;
    // font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9;
    height: 100%;

    &.unblocked {
        overflow: auto !important;
    }

    &.blocked.force {
        overflow: hidden !important;
    }
}