.an-button-select {
    display: flex;
    flex: 1;

    .an-button-select-iwrap {
        display: flex;
        flex-direction: row;
        height: 40px;
        border-radius: 2em;
        overflow: hidden;
        width: 180px;
        border: 1px solid rgb(221, 221, 221);
        flex: 1;
    }

    .an-button-select-button {
        border-top: none;
        border-right: 1px solid #ededed;
        border-bottom: none;
        border-left: none;
        border-image: initial;
        display: flex;
        flex: 1 1 0%;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        background: transparent;
        color: #444;
        cursor: pointer;

        &:last-child {
            border-right: 0;
        }

        &:focus {
            outline: none;
        }

        &.an-button-select-button--selected {
            background: #c72663;
            color: #fff
        }
    }
}