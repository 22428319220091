.an-label {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    margin: 0px 10px 10px;
    font-weight: 500;
    height: 24px;

    &.no-horizontal-margin {
        margin-left: 0;
        margin-right: 0;
    }

    &.no-vertica-margin {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .label {
        padding-right: 6px;
        font-size: 15px;
    }

    ion-icon {
        color: #c31459;
        font-size: 20px;
    }

    &.secondary {
        .label {
            font-size: 13px;
            color: #919191;
            font-weight: 400;
        }
    }
}