@import "~antd/dist/antd.less";
@import "theme/index.less";

//// TODO collapse

body {
  -webkit-overflow-scrolling: touch;
  .ant-modal {
    padding-bottom: 0 !important;
  }

  .ant-spin-text {
    margin-top: 15px;
  }
}

.main {
  .ant-tag {
    padding: 5px 7px;
    border-radius: 30px;
    background-color: #fcfbf7;
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;

    .ant-collapse-item {
      border: none;
      margin-bottom: 10px;

      &:last-child {
        border: none;

        > .ant-collapse-header {
          border-radius: 1em;
          border: 1px solid #ddd;
          background: #f0f0f078;
        }
      }
    }

    .ant-collapse-header {
      border-radius: 1em;
      border: 1px solid #ddd;
      background: #f0f0f078;
    }

    .ant-collapse-content {
      border: none;
      background-color: transparent;
    }

    .ant-collapse-content-box {
      padding: 0px !important;
      margin-top: 10px;
    }

    .ant-collapse-extra {
      font-size: 12px;
      line-height: 22px;
    }
  }
}

.main {
  .ant-alert {
    border-radius: 1em;
  }
}

/// Index.css

/////

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.an-max-height-40 {
  max-height: 40px !important;
}

.an-min-width-150 {
  min-width: 150px !important;
}

.an-min-width-170 {
  min-width: 170px !important;
}

.an-min-width-180 {
  min-width: 180px !important;
}

.round {
  border-radius: 2em !important;
}

/// Feed

.feed-screen-top-bar {
  transition: top 0.3s;
  position: relative;

  &.sticky {
    z-index: 900;

    @media (max-width: 760px) {
      background-color: #fff;
      position: sticky;
      top: 58px;
      border-bottom: 1px solid #a4a4a4;
    }
  }
}

.feed-screen-filters-buttons {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 0px 6px;
}

/// TopNav

.menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 760px) {
    display: none;
  }
}

.logo {
  max-width: 160px;
}

.nav-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 32px;

  @media (min-width: 760px) {
    display: none;
  }
}

/// Bottom Bar

body.modal {
  .bottom-bar {
    display: none;
  }
}

.bottom-bar {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1000;
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f4f4f4;
  border-top: 1px solid #a4a4a4;

  @media (min-width: 760px) {
    display: none;
  }

  .bottom-bar-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #c3125a;
    font-size: 11px;
    position: relative;

    > ion-icon {
      font-size: 24px;
      --ionicon-stroke-width: 45px;
      margin-bottom: 3px;
    }

    > span > ion-icon {
      font-size: 24px;
      --ionicon-stroke-width: 45px;
      color: #c3125a;
      margin-bottom: -3px;
    }

    > .ant-typography {
      color: #909090;
    }
  }
}

/// Search

.search-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  @media (max-width: 760px) {
    padding: 6px 10px;
  }

  @media (min-width: 760px) {
    &.sticky {
      z-index: 1000;
      position: sticky;
      top: 0px;
    }
  }

  .input {
    border-radius: 6em;
    height: 50px;
    font-family: Poppins;
    padding: 15px;
    box-shadow: none;

    // Themes

    &.primary {
      border-width: 2px !important;
      border-color: #c3125a;
    }

    &.secondary {
      border-width: 1px;
      border-color: "#ddd";
    }

    // Modifiers

    &.right-space {
      margin-right: 10px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    min-width: 48px;
    height: 48px;

    &.primary {
      border-color: #c3125a;
      border-width: 2px;
    }
  }

  .ant-badge-dot {
    width: 10px;
    height: 10px;
  }
}

/* Fade */

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* modal */
.modal-enter {
  opacity: 0;
  z-index: 1;
}

.modal-enter.modal-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

// label form

.an-form {
  &.ant-form-small {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  &.ant-form-medium {
    .ant-form-item {
      margin-bottom: 15px;
    }
  }

  &.ant-form-large {
    .ant-form-item {
      margin-bottom: 20px;
    }
  }

  .ant-form-item {
    margin-bottom: 40px;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
  margin: 6px 15px;
}

/// Drawer

.drawer-left-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
}

.drawer-header {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 2px dashed #b5b5b5;
}

.drawer-header-cont {
  display: flex;
  align-items: center;
  width: 90%;
}

.drawer-body ul {
  list-style: none;
  padding: 0;
  color: #300b43;
}

.drawer-body ul li {
  padding: 9px 0px;
}

.drawer-body ul li div {
  display: flex;
  align-items: center;
  width: 100%;
}

.drawer-body ul li div ion-icon {
  padding: 0px 6px 0px 0px;
  font-size: 18px;
  width: 18px;
}

.drawer-body ul li div a {
  color: #444444;
  font-size: 13px;
  line-height: 25px;
  flex: 1;
}

.list-error-container {
  &.margin-gap {
    margin: 15px;
  }
}

.experience-image-carousel-container {
  position: relative;

  .experience-favorite-icon-container {
    position: absolute;
    bottom: 12px;

    z-index: 50;
    background: rgba(255, 255, 255, 0.8);
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c3125a;
    border-radius: 35px;
    box-shadow: 0px 0px 2px 1px #ccc;
    cursor: pointer;
    left: 10px;
    &:hover {
      transform: scale(1.05);
    }

    .ant-spin-spinning {
      height: 25px;
      width: 25px;
    }
  }
}

.experience-share-container {
  position: absolute;
  bottom: 12px;
  right: 10px;
}

.share-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.share-button {
  height: 35px;
  padding: 0px !important;
  border-radius: 2em;
  width: 35px;
  margin-left: 10px;
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 2px 1px #ccc;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  &.share {
    height: 31px;
    width: 31px;
    z-index: 50;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c3125a;
    border-radius: 35px;
    box-shadow: 0px 0px 2px 1px #ccc;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }

  &.copy {
    background: #224080;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.close {
    background: rgba(63, 63, 63, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.experience-image-carousel {
  position: relative;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  background: #f5f5f6;

  .counter-container {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    padding: 2px 14px;
    border-radius: 2em;
    top: 12px;
    right: 10px;
    z-index: 50;
  }

  .stepper-container {
    display: none;

    @media (min-width: 760px) {
      position: absolute;
      width: 15%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      padding: 10px;
      transition: all 1s ease-in;

      &.right {
        right: 0;
        justify-content: flex-end;
      }

      .step-btn {
        display: none;
        border-radius: 50%;
        border: none;
        padding: 5px;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.85);
        outline: none;
        cursor: pointer;

        > ion-icon {
          font-size: 14px;
        }
      }

      &:hover {
        .step-btn {
          display: flex;
        }
      }
    }
  }
}

.tag-span {
  padding: 7px 14px;
  margin-right: 5px;
  color: #fff;
  border-radius: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgb(195, 18, 90);
  background: -moz-linear-gradient(
    90deg,
    rgba(195, 18, 90, 1) 23%,
    rgba(46, 10, 66, 1) 87%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(195, 18, 90, 1) 23%,
    rgba(46, 10, 66, 1) 87%
  );
  background: linear-gradient(
    90deg,
    rgba(195, 18, 90, 1) 23%,
    rgba(46, 10, 66, 1) 87%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c3125a", endColorstr="#2e0a42", GradientType=1);
}

.impersonated-session-modal-message {
  .ant-modal-body {
    white-space: pre-wrap;
  }
}

.impersonated-session-alert-container {
  @media (min-width: 760px) {
    margin-top: 10px;
  }

  .ant-alert {
    padding: 15px 10px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;

    .ant-alert-message {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-alert-description {
      font-size: 13px;
    }
  }
}

.experience-event-form {
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 1em;

  .form-item-label {
    margin-left: 10px;

    @media (min-width: 577px) {
      margin-left: 0;
    }
  }
}

.wallet-table {
  .ant-table-tbody > tr > td {
    padding: 16px 8px;
  }
}

@primary-color: #C3125A;@font-family: Poppins;@text-color: #444444;@heading-color: #333333;