#root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

/// Top Bar

.nav-container-owrap {
    height: 58px;
    padding: 10px;
    background-color: #fff;
    z-index: 1000;
    transition: top 0.3s; 
    
    @media (min-width: 760px) {
        padding: 10px 0px;
    }

    @media (max-width: 760px) {
        position: sticky;
        width: 100%;
        top: 0;
    } 
}

.nav-container-iwrap {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 760px) {
        max-width: 745px;
    }

    @media (min-width: 860px) {
        max-width: 830px;
    }

    @media (min-width: 1024px) {
        max-width: 930px;
    }
}

// Main Area

.screen-owrap {
    padding-bottom: 61px; // Padding to give space for bottom bar
    max-width: none;
    margin: 0px auto;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .screen-iwrap {
        display: flex;
        flex: 1;  
        
        @media (min-width: 760px) {
            margin-top: 10px;
        }
    }

    &.no-bottom-offset {
        padding-bottom: 15px;
    }

    @media (min-width: 760px) {
        max-width: 745px;
        padding-bottom: 15px;
        flex: none;
        background: #f9f9f9;

        &.no-bottom-offset {
            padding-bottom: 15px;
        }
    }

    @media (min-width: 860px) {
        max-width: 830px;
    }

    @media (min-width: 1024px) {
        max-width: 930px;
    }
}

.sidebar {
    float: left;
    margin-left: 45px;
    display: none;

    @media (min-width: 760px) {
        display: block;
        width: 250px;
    }

    @media (min-width: 860px) {
        display: block;
        width: 255px;
    }

    @media (min-width: 1024px) {
        max-width: 270px;
    }
}

.main {
    width: 100%;
    max-width: none;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    &.full {
        max-width: none;
    }

    &.horizontal-gap {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.vertical-gap {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &.top-gap {
        padding-top: 10px;
    }

    &.bottom-gap {
        padding-bottom: 10px;
    }

    @media (min-width: 760px) {
        max-width: 450px;
        border-radius: 3px;
        border: 1px solid #dbdbdb;

        &.full {
            // background-color: transparent;
            // border: none;
        }
    }

    @media (min-width: 860px) {
        max-width: 530px;

        &.full {
            max-width: 830px;
        }
    }

    @media (min-width: 1024px) {
        max-width: 615px;

        &.full {
            max-width: 930px;
        }
    }
}