.user-wallet-screen {
  .user-wallet-details-screen {
    padding: 15px;

    .user-wallet-details-title {
      margin-bottom: 10px;
      background-image: linear-gradient(
        to right,
        #c41f5d 0%,
        #a31b50 61%,
        #360c27 100%
      );
      border-radius: 8px;
      padding: 8px;

      > h1 {
        margin: 0;
        line-height: 25px;
        font-size: 15px;
        font-family: "Poppins";
        font-weight: 500;
        color: #fff;
      }

      > h2,
      > h3 {
        margin: 0px;
        font-size: 13px;
		color: rgb(231, 231, 231);
		font-weight: 400;
      }

      > h3 {
		font-size: 12px;
      }
    }

    .ant-descriptions-header {
      margin-bottom: 10px;
    }

    .ant-descriptions-title {
      flex: auto;
      overflow: hidden;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5715;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-descriptions-view {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 8px 10px;
    }

    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding: 5px 0px;
    }

    .ant-descriptions-item-label {
      font-weight: 500;
      font-size: 13px;
      line-height: 1.5715;
      text-align: start;
    }
  }
}
