.an-select.ant-select {
    width: 100%;
    
    .ant-select-selection-search {
        left: 16px !important;
    }

    &.round {
        > div {
            border-radius: 2em !important;
        }
    }

    > div {
        border-radius: 6px !important;
        padding: 5px 16px !important;
        height: 50px !important;
        font-family: 'Poppins';
        font-size: 14px;
    }

    input {
        padding: 10px 0px !important;
        height: 48px !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        line-height: 39px !important;
    }

    .ant-select-arrow {
        right: 16px
    }
}