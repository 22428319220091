.login-screen {
  .login-screen-container {
    padding: 15px;
    max-width: 600px;
    margin: 0px auto;
    width: 100%;
    height: 100%;
  }

  .login-screen-iwrap {
    padding-bottom: 20px;
  }

  .login-img {
    text-align: center;
    max-width: 300px;
    width: 300px;
    height: 247px;
    margin: 0 auto;
  }

  .login-img img {
    max-width: 300px;
    width: 300px;
    height: 247px;
  }

  .login-comp-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-comp-header {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-template-rows: 1fr;
  }

  .login-back-btn {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 18px;
  }

  .login-back-btn ion-icon {
    color: #c3125a;
  }

  .summary-items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .summary-item-container {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid #dddddd6b;
    border-radius: 2em;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: #fafafa;

    .summary-title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px;
    }

    .summary-value-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 15px;
    }
  }
}
