.booking-list-item {
  &.ctn {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px;
    padding-bottom: 8px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .booking-list-item-head {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;

    .booking-list-item-status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      > button {
        border-radius: 12px;
        margin-top: 8px;

        > ion-icon {
          margin-bottom: -1px;
          padding-right: 4px;
        }
      }
    }
  }

  .user-booking-result-body {
    padding-top: 6px;
    width: 100%;

    .user-booking-result-client-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 12px;
      margin-bottom: 5px;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 7px; 

      > div {
        display: flex;
        align-items: center;
      }
    }

    .user-booking-result-body-info {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        border: 1px solid #ddd;
        border-radius: 3px;
        margin-right: 5px;
      }

      .booking-list-item-price {
        background-color: #c3125a;
        border: none;
        border-radius: 3px;
        padding: 2px 4px;
      }
    }
  }
}
