.DayPicker {
    position: relative;
    font-family: Poppins;
    border-radius: 1em;
    margin: 0 -3px;
  
    .DayPicker-wrapper {
      &:focus {
        outline: none;
      }
    }
  
    .DayPicker-Caption {
      font-weight: 400;
      text-transform: uppercase;
      padding: 10px 0px;
      margin: 0px 3px 3px;
      text-align: center;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      color: #7a7a7a;
      background: #dddddd30;
    }
  
    .DayPicker-NavBar {
      position: absolute;
      top: 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      width: 100%;
  
      button {
        border: transparent;
        padding: 6px 6px;
        position: absolute;
        top: 5px;
        background: transparent;
  
        &.DayPicker-NavButton--prev {
          left: 3px;
        }
  
        &.DayPicker-NavButton--next {
          right: 3px;
        }
  
        &:focus {
          outline: none;
        }
  
        ion-icon {
          font-size: 20px;
          color: #7a7a7a;
        }
      }
    }
  
    .DayPicker-Months {
      display: flex;
      position: relative;
  
      .DayPicker-Month {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        .DayPicker-WeekdaysRow {
          display: flex;
          flex-direction: row;
          flex: 1;
  
          .DayPicker-Weekday {
            justify-content: center;
            align-items: center;
            display: flex;
            flex: 1 1;
            border-bottom: none;
            padding: 10px 0px;
            background: #fff;
            border: 1px solid #f0f0f0;
            color: #7a7a7a;
            margin: 3px;
            border-radius: 3px;
            background: #dddddd30;
  
            abbr {
              text-decoration: none;
            }
          }
        }
      }
  
      .DayPicker-Body {
        display: flex;
        flex: 1 1;
        flex-direction: column;
      }
  
      .DayPicker-Week {
        flex: 1 1;
        display: flex;
        flex-direction: row;
      }
  
      .DayPicker-Day {
        display: flex;
        flex: 1 1;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        border: 1px solid #e7e7e7;
        color: #757575;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
  
        &:not(.DayPicker-Day--disabled):hover {
          border: 1px solid #c31459 !important;
        }
  
        &.DayPicker-Day--disabled {
          background: #f0f0f0;
          color: #c6c6c6;
          cursor: not-allowed !important;
          opacity: 0.5;
        }
  
        &.DayPicker-Day--selected {
          background-color: #fff;
          color: #c31459;
          cursor: pointer;
          border: 1px solid #e5a4b9;
  
          &.DayPicker-Day--highlighted {
            background-color: #c31459;
            color: #fff;
            border: 1px solid #c31459;
          }
        }
  
        &.DayPicker-Day--highlighted {
          background-color: #c31459;
          color: #fff;
          border: 1px solid #c31459;
        }
  
        &.DayPicker-Day--outside {
          visibility: hidden;
          height: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }