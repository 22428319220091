.an-checkbox {
    width: 100%;

    .an-checkbox-item {
        margin-bottom: 10px;
        border-radius: 2em;
        border: 1px solid rgb(240, 240, 240);
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 13px 16px;
            border-radius: 2em;

            &.ant-checkbox-wrapper-checked {
                background-color: #FCFBF7;
            }
        }

        span {
            flex: 1;
            padding: 0
        }

        span.ant-checkbox {
            flex: none
        }
    }
}