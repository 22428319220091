.experiences-filters-modal {
    .remove-filters-button-container {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: flex-end;
        z-index: 500;

        > button {
            background-color: #fff !important;
        }
    }
    
    .filter-section-title {
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 20px;
    }

    .filter-section-input-title {
        font-family: Roboto;
        font-weight: 400;
        color: rgb(204, 204, 204);
        margin-left: 16px;
        margin-bottom: 8px;
        line-height: 14px;
        font-size: 14px;
    }

    .an-slider {
        .an-slider-value-label {
            top: -40px;
            font-size: 16px;
        }
    }
}