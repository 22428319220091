.ant-picker.an-input {
    cursor: pointer;

    &.ant-picker-disabled {
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
    }

    &.an-count-select {
        input {
            pointer-events: all;
        }
    }

    input {
        cursor: inherit !important;
        pointer-events: none;
    }

    .ant-picker-suffix {
        display: flex;
        flex-direction: row;
        pointer-events: all;

        ion-icon {
            font-size: 21px;
        }

        button {
            background-color: transparent;
            border-radius: 0;
            border: none;
            outline: none;
            padding: 0;
            height: 38px;
            padding-left: 10px;
            border-right: 1px solid #d6d6d670;
            padding-right: 10px;
            border-left: 1px solid #d6d6d670;
            cursor: pointer;

            &:last-child {
                border-right: 0;
                padding-right: 0;
            }

            &:hover {
                ion-icon {
                    color: #c31459
                }
            }
        }
    }
}

.an-time-picker-options-container {
    height: 300px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.an-time-picker-column-owrap {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;

    .title {
        text-align: center;
        font-weight: 500;
        margin-bottom: 5px;
    }
}

.an-time-picker-column-iwrap {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: auto;
    flex: 1;
    padding: 0px 20px;

    &:first-child {
        align-items: flex-end;
    }

    &:last-child {
        align-items: flex-start;
    }
}

.an-time-picker-option {
    padding: 10px;
    background: #f7f7f7;
    border: 1px solid #ddd;
    width: 100px;
    border-radius: 6px;
    margin-bottom: 8px;
    font-weight: 500;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background: #c3145936;
    }

    &.an-time-picker-option--selected {
        background-color: #c31459;
        color: #fff;
        border: 1px solid #c31658;
    }
}