.experience-detail-screen {
    .experience-information-container {
        padding: 15px 15px;
        
        .experience-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
        }

        .experience-information-item-title {
            margin-bottom: 25px;
        }
        
        .experience-details-list {
            display: flex;
            flex-direction: column;
        
            .experience-details-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    margin: 2px 7px;
                }

                ion-icon {
                    color: #C3125A;
                    font-size: 22px;
                    width: 22px;
                }
            }
            
            .experience-description {
                padding: 10px;
            }
        }

        .experience-booking-details-list {
            display: flex;
            flex-direction: column;
            
            .experience-booking-details-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    margin: 2px 7px;
                }

                ion-icon {
                    color: #C3125A;
                    font-size: 22px;
                    width: 22px;
                }
            }
        }
        
        .experience-tags {
            display: flex;
            flex-direction: row;
        }
        
        .experience-chat-button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }   
}


.selected-day-events-container {
    margin-top: 20px;
    
    .title-container {
        padding: 10px 0px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;


        .title {
            font-size: 15px;
            font-weight: 500;
            margin-left: 10px;
        }

        ion-icon {
            font-size: 23px;
        }
    }

    .selected-day-event-item {
        background: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 6px;
        margin-bottom: 10px;

        .event-item-time-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .event-item-time {
                margin-left: 10px;
                font-size: 15px;
            }
        }
    }
} 