
.booking-screen {
    .main-container {
        padding: 20px 15px;
        position: relative;
    }
    
    .summary-items-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .summary-item-container {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        border: 1px solid #dddddd6b;
        border-radius: 2em;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        background-color: #fafafa;
        
        .summary-title-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 15px;

            .title {

            }
        }

        .summary-value-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 15px;
        }
    }
}
.booking-stepper-ctn {
    padding: 5px !important;
    font-family: "Roboto" !important;
}

.booking-step-step {
    outline: none;
    font-family: "Poppins";
}

.booking-step-one-select > div {
    border-radius: 8px !important;
    height: 40px !important;
}

.booking-step-one-select input {
    padding: 10px 0px !important;
    height: 40px !important;
}