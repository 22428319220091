.user-proveedor-report-info-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 5%;
	padding-left: 15px;
	padding-right: 15px;
	height: 320px;
}

.user-proveedor-report-info-stat1>div, .user-proveedor-report-info-stat2>div, .user-proveedor-report-info-stat3>div, .user-proveedor-report-info-stat4>div, .user-proveedor-report-info-stat5>div {
	text-align: center;
	background: rgb(195, 18, 90);
	background: -moz-linear-gradient(90deg, rgba(195, 18, 90, 1) 23%, rgba(46, 10, 66, 1) 87%);
	background: -webkit-linear-gradient(90deg, rgba(195, 18, 90, 1) 23%, rgba(46, 10, 66, 1) 87%);
	background: linear-gradient(90deg, rgba(195, 18, 90, 1) 23%, rgba(46, 10, 66, 1) 87%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c3125a", endColorstr="#2e0a42", GradientType=1);
}

.user-proveedor-report-info-stat1 {
	grid-area: 1 / 1 / 2 / 2;
}

.user-proveedor-report-info-stat2 {
	grid-area: 1 / 2 / 2 / 3;
}

.user-proveedor-report-info-stat3 {
	grid-area: 2 / 1 / 3 / 2;
}

.user-proveedor-report-info-stat4 {
	grid-area: 2 / 2 / 3 / 3;
}

.user-proveedor-report-info-stat5 {
	grid-area: 3 / 1 / 4 / 2;
}

.user-proveedor-report-info-stat6 {
	grid-area: 3 / 2 / 4 / 3;
}

.stats-day-picker{
	width: 110px;
}