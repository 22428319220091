.user-experience-form-screen {
    .ant-upload.ant-upload-select-picture {
        display: block;
        
        .ant-btn {
            width: 100%;
            border-radius: 10px;
            height: 70px;
            border: 1px dashed #e1e1e1;
        }
    }
    
    .ant-upload-list-picture {
        margin-top: 16px;

        .ant-upload-list-picture-container {
            padding: 10px 0px;
            border-bottom: 1px solid #e1e1e1;

            &:last-child {
                border-bottom: 0;
            }
        }
        
        .ant-upload-list-item {
            border: none;
            padding: 0px;
            margin: 0px;
            height: auto;
            
            .ant-upload-list-item-info {

            }
            
            .ant-upload-list-item-thumbnail {
                width: 117.33px;
                height: 66px;
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                overflow: hidden;
                box-sizing: content-box;
                padding: 2px;
                
                .ant-upload-list-item-image {
                    display: block;
                    width: 117.33px;
                    height: 66px;
                    border-radius: 8px;
                }
            }
            
            .ant-upload-list-item-name {
                padding-right: 35px;
            }
            
            .ant-upload-list-item-card-actions-btn {
                height: 40px;
                width: 40px;
                border-radius: 10px;
                
                .anticon {
                    color: #bd1e5a;
                    font-size: 20px;
                }
            }
        }
    }
    
    .ant-upload-list-item-progress {
        .ant-progress-inner {
            display: none;
        }
    }

    .create-mode-selector-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        @media (min-width: 400px) {
            flex-direction: row;
        }
        
        .create-form-mode-button {
            font-weight: 500;
    
            @media (min-width: 400px) {
                margin: 0px 10px;
            }
            
            &:first-child {
                margin-bottom: 10px;
                
                @media (min-width: 400px) {
                    margin-left: 0;
                    margin-bottom: 0;
                }
            }
            
            &:last-child {
                @media (min-width: 400px) {
                    margin-right: 0;
                }
            }
        }
        
        .create-form-mode-button--active {
            color: #c31459;
        }
    }
}
