.an-modal {
    .modal-backdrop {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.75);
        top: 0px;
        left: 0px;
        z-index: 1000;
        overflow-y: scroll;
        position: fixed;
    }
    
    .modal-container {
        margin: 0px auto;
        background: rgb(255, 255, 255);
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 100%;
        flex-direction: column;
        
        @media (min-width: 760px) {
            height: 80%;
            max-width: 800px;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 1px 2px;
            border: 1px solid #ddd;
        }
    }
    
    .modal-header-owrap {
        background: #fff;
        border-bottom: 1px solid #ddd;
        width: 100%;
        z-index: 100;
        // position: absolute;
        top: 0;
        left: 0;
    }
    
    .modal-header-iwrap {
        padding: 10px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .modal-header-title {
        font-family: Poppins;
        font-weight: 500;
        font-size: 25px;
        color: rgb(167, 18, 72);
    }
    
    .modal-header-buttons-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .modal-body {
        padding: 20px 12px;
        height: 100%;
        overflow-y: auto;
        // border-top: 61px solid transparent;
        position: relative;
        box-sizing: border-box;
        
        &.full {
            padding: 0px;
        }
    }
}